<template>
  <div class="PhysicianVisitsTow">
    <h3>您的身体健康情况描述</h3>
    <div class="boxp">

      <div class="fxk padd-30">
        <p class="titles">是否有眼病史?</p>
        <el-radio-group v-model="yb" class="sh">
          <div class="sh padd-30 w">
            <el-radio :label=0>否</el-radio>
          </div>
          <div class="sh w">
            <el-radio :label=1>是</el-radio>
          </div>
          <div class="sr-boxs">
            <el-select
                v-model="postData.S_OP_ICH_FPDes1"
                v-show="yb"
                class="sr"
                filterable
                allow-create
                placeholder="请选择">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <el-input v-show="yb" class="cxsj" v-model="postData.S_OP_ICH_sicknessDuration1" placeholder="持续时间"></el-input>
          </div>
        </el-radio-group>
      </div>

      <div class="fxk padd-30">
        <p class="titles">是否有全身疾病/用药史?</p>
        <el-radio-group v-model="jb" class="sh">
          <div class="sh padd-30 w">
            <el-radio :label=0>否</el-radio>
          </div>
          <div class="sh w">
            <el-radio :label=1>是</el-radio>
          </div>
          <div class="sr-boxs">
            <el-select
                v-model="postData.S_OP_ICH_FPDes2"
                v-show="jb"
                class="sr"
                filterable
                allow-create
                placeholder="请选择">
              <el-option
                  v-for="item in options2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <el-input v-show="jb" class="cxsj" v-model="postData.S_OP_ICH_sicknessDuration2" placeholder="持续时间"></el-input>
          </div>
        </el-radio-group>
      </div>

      <div class="fxk padd-30">
        <p class="titles">是否有过敏史?</p>
        <el-radio-group v-model="gm" class="sh">
          <div class="sh padd-30 w">
            <el-radio :label=0>否</el-radio>
          </div>
          <div class="sh w">
            <el-radio :label=1>是</el-radio>
          </div>
          <div class="sr-boxs">
            <el-input v-show="gm" v-model="postData.S_OP_ICH_FPDes3" class="sr" placeholder="请输入内容"></el-input>
            <el-input v-show="gm" class="cxsj" v-model="postData.S_OP_ICH_sicknessDuration3" placeholder="持续时间"></el-input>
          </div>
        </el-radio-group>
      </div>

      <div class="fxk padd-30">
        <p class="titles">一周内是否有疾病/用药</p>
        <el-radio-group v-model="pj" class="sh">
          <div class="sh padd-30 w">
            <el-radio :label=0>否</el-radio>
          </div>
          <div class="sh w">
            <el-radio :label=1>是</el-radio>
          </div>
          <div class="sr-boxs">
            <el-input v-show="pj" v-model="postData.S_OP_ICH_FPDes4" class="sr" placeholder="请输入内容"></el-input>
            <el-input v-show="pj" class="cxsj" v-model="postData.S_OP_ICH_sicknessDuration4" placeholder="持续时间"></el-input>
          </div>
        </el-radio-group>
      </div>

      <div class="fxk padd-30">
        <p class="titles">父亲是否近视？</p>
        <el-radio-group v-model="fq" class="sh">
          <div class="sh padd-30 w">
            <el-radio :label=0>否</el-radio>
          </div>
          <div class="sh w">
            <el-radio :label=1>是</el-radio>
          </div>
          <div class="sr-boxs">
            <el-input v-show="fq" v-model="postData.S_OP_ICH_FPDes5" class="sr" placeholder="请输入内容"></el-input>
            <el-input v-show="fq" class="cxsj" v-model="postData.S_OP_ICH_sicknessDuration5" placeholder="持续时间"></el-input>
          </div>
        </el-radio-group>
      </div>

      <div class="fxk padd-30">
        <p class="titles">母亲是否近视？</p>
        <el-radio-group v-model="mq" class="sh">
          <div class="sh padd-30 w">
            <el-radio :label=0>否</el-radio>
          </div>
          <div class="sh w">
            <el-radio :label=1>是</el-radio>
          </div>
          <div class="sr-boxs">
            <el-input v-show="mq" v-model="postData.S_OP_ICH_FPDes6" class="sr" placeholder="请输入内容"></el-input>
            <el-input v-show="mq" class="cxsj" v-model="postData.S_OP_ICH_sicknessDuration6" placeholder="持续时间"></el-input>
          </div>
        </el-radio-group>
      </div>

      <div class="fxk padd-30">
        <p class="titles">是否早产?出生体重</p>
        <el-radio-group v-model="zc" class="sh">
          <div class="sh padd-30 w">
            <el-radio :label=0>否</el-radio>
          </div>
          <div class="sh w">
            <el-radio :label=1>是</el-radio>
          </div>
          <div class="sr-boxs">
            <el-input v-show="zc" v-model="postData.S_OP_ICH_FPDes7" class="sr" placeholder="请输入内容"></el-input>
          </div>
        </el-radio-group>
      </div>

      <div class="fxk padd-30">
        <p class="titles">母亲妊娠时是否生病？</p>
        <el-radio-group v-model="rc" class="sh">
          <div class="sh padd-30 w">
            <el-radio :label=0>否</el-radio>
          </div>
          <div class="sh w">
            <el-radio :label=1>是</el-radio>
          </div>
          <div class="sr-boxs">
            <el-input v-show="rc" v-model="postData.S_OP_ICH_FPDes8" class="sr" placeholder="请输入内容"></el-input>
          </div>
        </el-radio-group>
      </div>

      <div style="display: flex; align-items: center; padding: .2rem 0">
        <p>既往屈光矫正方式：</p>
        <el-checkbox-group v-model="qgjws">
          <el-checkbox :label="0">框架眼镜</el-checkbox>
          <el-checkbox :label="1">软性角膜接触镜</el-checkbox>
          <el-checkbox :label="2">RGP</el-checkbox>
          <el-checkbox :label="3">OK镜片</el-checkbox>
          <el-checkbox :label="4">屈光手术</el-checkbox>
          <el-checkbox :label="5">无</el-checkbox>
        </el-checkbox-group>
      </div>
    </div>

    <div class="bz">
      <p>备注：</p>
      <el-input
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 5}"
          placeholder="请输入内容"
          v-model="postData.S_OP_ICH_FPremark">
      </el-input>
    </div>
  </div>
</template>

<script>
export default {
  name: "teenagerBody",
  data() {
    return {
      checkList: [],
      options: [
        {
          value: '角膜炎',
          label: '角膜炎'
        },
        {
          value: '鼻炎',
          label: '鼻炎'
        },
        {
          value: '鼻窦炎',
          label: '鼻窦炎'
        },
        {
          value: '眼外伤',
          label: '眼外伤'
        },
        {
          value: '结膜炎',
          label: '结膜炎'
        },
        {
          value: '干眼症',
          label: '干眼症'
        }
      ],
      options2: [
        {
          value: '糖尿病',
          label: '糖尿病'
        },
        {
          value: '强直性脊柱炎',
          label: '强直性脊柱炎'
        },
        {
          value: '干眼综合症',
          label: '干眼综合症'
        },
        {
          value: '系统性红斑狼疮',
          label: '系统性红斑狼疮'
        },
        {
          value: '甲状腺异常',
          label: '甲状腺异常'
        },
        {
          value: '风湿性关节炎',
          label: '风湿性关节炎'
        },
        {
          value: '安定',
          label: '安定'
        },
        {
          value: '免疫抑制剂',
          label: '免疫抑制剂'
        },
        {
          value: '阿托品等',
          label: '阿托品等'
        },
        {
          value: '肝炎',
          label: '肝炎'
        },
        {
          value: '肾炎',
          label: '肾炎'
        }
      ],
      sr: '',
      textarea2: '',
      idx: [0,0,0,0,0,0],
      postData: {},
      qgjws: [],
      yb: 0,
      jb: 0,
      gm: 0,
      pj: 0,
      fq: 0,
      mq: 0,
      zc: 0,
      rc: 0
    }
  },
  watch: {
    postData: {
      handler(n, o) {
        if (o) {
          this.$store.commit('physicianVisits/upTeenagerData', {type: 4, obj: n})
        }
      },
      immediate: true,
      deep: true
    },
    "$store.state.physicianVisits.isgetData":function (n, o) {
      setTimeout(() => {
        this.Initialize()
      }, 500)
    },
    yb: function (n, o) {
      // console.log(n)
      let arr = []
      if (this.yb) {
        arr = [1, 0]
      }else {
        arr = [0, 1]
      }
      arr = arr.join(',')
      this.postData.S_OP_ICH_FPArray1 = arr
    },
    gm: function () {
      let arr = []
      if (this.gm) {
        arr = [1, 0]
      }else {
        arr = [0, 1]
      }
      arr = arr.join(',')
      this.postData.S_OP_ICH_FPArray3 = arr
    },
    jb: function () {
      let arr = []
      if (this.jb) {
        arr = [1, 0]
      }else {
        arr = [0, 1]
      }
      arr = arr.join(',')
      this.postData.S_OP_ICH_FPArray2 = arr
    },
    pj: function () {
      let arr = []
      if (this.pj) {
        arr = [1, 0]
      }else {
        arr = [0, 1]
      }
      arr = arr.join(',')
      this.postData.S_OP_ICH_FPArray4 = arr
    },
    fq: function () {
      let arr = []
      if (this.fq) {
        arr = [1, 0]
      }else {
        arr = [0, 1]
      }
      arr = arr.join(',')
      this.postData.S_OP_ICH_FPArray5 = arr
    },
    mq: function (n, o) {
      let arr = []
      if (this.mq) {
        arr = [1, 0]
      }else {
        arr = [0, 1]
      }
      arr = arr.join(',')
      this.postData.S_OP_ICH_FPArray6 = arr
    },
    zc: function () {
      let arr = []
      if (this.zc) {
        arr = [1, 0]
      }else {
        arr = [0, 1]
      }
      arr = arr.join(',')
      this.postData.S_OP_ICH_FPArray7 = arr
    },
    rc: function () {
      let arr = []
      if (this.rc) {
        arr = [1, 0]
      }else {
        arr = [0, 1]
      }
      arr = arr.join(',')
      this.postData.S_OP_ICH_FPArray8 = arr
    },
    qgjws: function (n, o) {
      let arr = [0,0,0,0,0,0]
      for (let i = 0; i < n.length; i++) {
        arr[n[i]] = 1
      }
      this.postData.S_OP_ICH_HistoryArray = arr.join(',')
    }
  },
  created() {

  },
  methods: {
    Initialize() {
      this.yb = 0
      this.gm = 0
      this.jb = 0
      this.pj = 0
      this.fq = 0
      this.mq = 0
      this.zc = 0
      this.rc = 0
      let obj = JSON.parse(JSON.stringify(this.$store.state.physicianVisits.teenager))
      let datas = this.$store.state.physicianVisits.teenagerJkms
      if (obj.S_OP_ChooseId) {
        for (let keys in datas) {
          datas[keys] = obj.S_OP_Json[keys]
        }
      }
      this.postData = datas
      this.yb = this.clarr(this.postData.S_OP_ICH_FPArray1)
      this.jb = this.clarr(this.postData.S_OP_ICH_FPArray2)
      this.gm = this.clarr(this.postData.S_OP_ICH_FPArray3)
      this.pj = this.clarr(this.postData.S_OP_ICH_FPArray4)
      this.fq = this.clarr(this.postData.S_OP_ICH_FPArray5)
      this.mq = this.clarr(this.postData.S_OP_ICH_FPArray6)
      this.zc = this.clarr(this.postData.S_OP_ICH_FPArray7)
      this.rc = this.clarr(this.postData.S_OP_ICH_FPArray8)
      this.qgjws = this.zkarr(this.postData.S_OP_ICH_HistoryArray)
    },
    clarr (str) {
      if (!str || str.length <= 0) return 0
      let arrs = str.split(',')
      if (arrs[0] == 1) {
        return 1
      }else if (arrs[1] == 1){
        return 0
      }else {
        return 0
      }
    },
    zkarr(str) {
      if (!str) return []
      let arr = str.split(',')
      let item = []
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] == 1) {
          item.push(i)
        }
      }
      return item
    }
  }
}
</script>

<style scoped lang="scss">
.PhysicianVisitsTow {
  padding: 2vh 0;
}
.sh {
  display: flex;
  align-items: center;
  p {padding-left: 0.1rem}
}
 ::v-deep .fxk  .el-checkbox-group {font-size: 14px;}
 ::v-deep .fxk  .el-checkbox__inner::after {
  width: 6px;
  height: 14px;
  left: 8px;
  top: 2px;
}
 ::v-deep .fxk  .el-checkbox__inner {
  width: 25px;
  height: 25px;
}
 ::v-deep .fxk  .el-checkbox__label {display: none;}
.nr-box {
  img {
    width: 10vw;
    border-radius: 5px;
  }
}
.boxp {
  width: 82vw;
  padding: 0 3vw;
  display: flex;
  flex-wrap: wrap;
}
.sr {width: 2rem;}
.padd-30 {padding-right: 0.5rem}
.titles {
  padding-top: 0.25rem;
  text-align: left;
}
.w {width: 0.9rem}
.bz {
  width: 76vw;
  padding: 0 3vw;
  text-align: left;
  padding-top: 0.3rem;
}
.sr-boxs {
  width: 4rem;
  height: 40px;
  margin-left: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: start;
  .cxsj {width: 1.4rem; margin-left: .1rem;}
}
.bz {
  width: 76vw;
  padding: 0 3vw;
  text-align: left;
  padding-top: 0.3rem;
}
</style>
